//deltapeng button 240110 1551
//tailwind
///to override classes, the ones further to the right override ones on the left
import React, { MouseEvent, ReactNode } from "react";

//including this gives intellisense
interface ButtonProps {
  children: ReactNode; // This is the type for children
  // Define the possible variants
  variant?:
    | ""
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "gray"
    | "dark"
    | "light";
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  id?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = "",
  onClick = null,
  className = "",
  style = {},
  type = "button",
  disabled = false,
  id,
}) => {
  const handleOnClick = (event: any) => {
    // Call the provided onClick function if it exists
    if (onClick) {
      onClick(event);
    } else {
      console.log("you forgot onClick, custom dp Tailwind button");
    }
  };

  //for reasons unknown doesn't like conflicting text-white and text-black, so specify indiv
  let variantClass = "";
  if (variant === "primary" || (type == "submit" && variant == "")) {
    variantClass =
      "text-white bg-blue-500 hover:bg-blue-700 active:bg-blue-800";
  } else if (variant === "secondary") {
    variantClass =
      " text-white bg-purple-600 hover:bg-purple-700 active:bg-purple-800";
  } else if (variant === "success") {
    variantClass =
      "text-white bg-green-500 hover:bg-green-600 active:bg-green-700";
  } else if (variant === "info") {
    variantClass =
      "text-white bg-teal-500 hover:bg-teal-600 active:bg-teal-700";
  } else if (variant === "warning") {
    variantClass = "bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-600";
  } else if (variant === "danger") {
    variantClass =
      "text-white bg-rose-600 hover:bg-rose-700 active:bg-rose-800";
  } else if (variant === "light") {
    variantClass = "bg-gray-100 hover:bg-gray-300 active:bg-gray-400";
  } else if (variant === "gray" || (type == "reset" && variant == "")) {
    variantClass =
      " text-white bg-gray-500 hover:bg-gray-600 active:bg-gray-700";
  } else if (variant === "dark") {
    variantClass = " text-white bg-black hover:bg-gray-700 active:bg-gray-500";
  } else {
    variantClass =
      "bg-gray-100 hover:bg-gray-300 active:bg-gray-400 hover:border-black hover:border-2 active:border-black active:border-[3px] active:border-dashed";
  }

  return (
    <button
      id={id}
      type={type}
      className={`py-2 px-3 rounded ${className} ${variantClass} ${
        disabled ? "opacity-60 pointer-events-none cursor-not-allowed" : ""
      }`}
      onClick={handleOnClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
