/*EDIT HISTORY:  
 220101 TSN created for pic upload
*/
import React, { useState, useEffect } from 'react';

import ImageContainerCardEdit from './ImageContainerCardEdit';
import PicUploadWFields from './PicUploadWFields';
import PicEditWFields from './PicEditWFields';
import moment from 'moment';

import constants from "./constants";

const PicList: React.FC<any> = (props) => {

	let strDateFormat = 'ddd, MMM D YYYY, h:mm a'

	//const { picData, refreshMemberPicState,  stateTopic } = props; 
	const [isLoaded, setIsLoaded] = useState(false);
	const [thePicData, setThePicData] = useState([]);

	//toggles back and forth to manually refresh topic lists in MyProfile when altered in EditProfile
	const [manualRefreshTopics, SetManualRefreshTopics] = useState(false);
	const GoRefreshPics = () => {
		//altering this value should kick off the refresh useEffect
		SetManualRefreshTopics(!manualRefreshTopics)
	}

	//load all topic data, to be passed down into child components
	//need useEffect to transfer the result data into an array
	useEffect(() => {
		//console.log("about to loadMember, check", memberData)

		fetch(`${constants.baseURL}/api/file/pics`,
			{
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					passkey: 'showmethemoney',
				})
			})
			.then(response => response.json())
			//according to the API we made, the data returned will be the user this time, so for clarification change var data below to user
			.then(returnPicData => {  //add conditional to prevent login if wrong password, based on response message sent back
				//console.log("this updated data is the data we just saved. Should match the list", returnData)

				console.log("returnPicData", returnPicData)
				if (returnPicData) {
					setThePicData(returnPicData)
				}
				else {
					//console.log("fail??") 
					setThePicData(
						[]
					)
					//setStatusMsg("<< list failed to update >>") 
				}

				setIsLoaded(true);
			})
	}, [manualRefreshTopics]);

	//don't display site if data is not loaded yet
	if (!isLoaded) {
		return <div className="mx-3">
			<div className="my-1 progress">
				<div className="progress-bar progress-bar-striped progress-bar-animated bg-info"
					role="progressbar"
					style={{ width: "35%" }}></div>
			</div>
			<p>Loading pic data...</p>
		</div>
	}

	// experimenting column cascade, need window size though to guesstimate num of cols/cards, <div className="card" style={{ width: "18rem", margin: "5px", marginTop: "" + ((index % 3) * 50) + "px" }}></div>
	return (
		<div>
			<h3>Add to Picture List</h3>
			<div className="ml-3 mb-3">
				<PicUploadWFields cbRefreshMemberPicState={GoRefreshPics} uploadDestination={`PictureLists`} uploadKey="picLists" uploadCurr={1} uploadLimit={3} />
			</div>

			<div style={{ borderTop: "4px solid gray", marginBottom: "15px" }} />

			<div className="container-fluid">
				<div className="row">
					{
						thePicData.length > 0
							? thePicData.map((thePic: any, index: any) => {

								return <div key={`${thePic.id}_${thePic.name}_${thePic.dateUpdated}_edit`} >
									<div className="card" style={{ width: "18rem", margin: "5px", marginTop: "20px" }}>
										<ImageContainerCardEdit imgId={thePic.id} imgName={thePic.name} picBase64={thePic.picbase64} isEditable={true} status={thePic.status} cbRefreshOnDelete={GoRefreshPics}
											style={{ marginTop: (index % 2 === 0) ? "40px" : "0px" }}
										/>
										<div className="card-body">
											<h5 className="card-title">{thePic.name}</h5>
											<pre className="card-text">
												{`${thePic.key_id} 
id[${thePic.id}] :: ${thePic.rating} rating :: ${thePic.location}
$${thePic.price} :: ${thePic.weight} oz
$${Math.round((thePic.price / thePic.weight) * 100) / 100}/oz :: $${Math.round((thePic.price * 16 / thePic.weight) * 100) / 100}/lb
${moment(thePic.dateUpdated).format(strDateFormat)}`}
												<hr />
												{`pros: ${thePic.pro}
cons: ${thePic.con}`}
											</pre>
											<PicEditWFields cbRefreshMemberPicState={GoRefreshPics} uploadDestination={`PictureLists`} uploadKey="picLists" uploadCurr={1} theOrigPic={thePic} />

										</div>
									</div>
								</div>
							})
							: "[ No pics uploaded for this user OR has not given sorted lists any Star Ratings ]"
					}
				</div>
			</div>

			<hr />

		</div>
	);
}

export default PicList;
