import React, { useState } from "react";
import Resizer from "react-image-file-resizer";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import constants from "./constants";

const PicUploadWFields: React.FC<any> = (props) => {
  // where stateTopic consists of all your topic data, and ~should be ordered by stars already
  const {
    cbRefreshMemberPicState,
    uploadDestination,
    uploadKey,
    uploadCurr,
    uploadLimit,
  } = props;

  const [selectedFile, setSelectedFile] = useState({} as any);
  //const [selectedFileThumbnail, setSelectedFileThumbnail] = useState({} as any);
  const [isUploading, setIsUploading] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");

  var onChangeHandlerPic = async (event: any) => {
    // 1 257 900  = 1.258MB from a 1.19MB pic   + 5.7%
    // 19 593 984 <- 18.6MB   + 5.3%
    // so say max size is 3MB, allow 106% -> 3.18MB -> 3 180 000
    //array of all stored files
    //console.log("here is the  filelist",event.target.files)
    //allow initial 15MB, but should reduce below
    try {
      //format is: FileList[ File, File] aka [{name, size, type, ...}, ...]
      const file = event.target.files[0];
      console.log("event.target.files", event.target.files);
      console.log("check ini file size now", file);
      // file, quality, width, height
      // aim for images ~ 1000x1000, and not being larger than 200kb a piece else initial load time is long (after, imgs are cached and loads faster)
      const resizedImage: any = await resizeFile(file, 30, 1000, 1000);

      var newBlob = dataURIToBlob(resizedImage);
      // a blob is almost a file, it's just missing a couple properties (leaving out lastModified auto gives it the curr time it seems)
      // add a few metadata items to make blob into file
      var newFile = new File([newBlob], file.name, { type: "image/jpeg" });

      console.log("new and improved newFile?", newFile);

      //max size 1.6MB
      if (resizedImage.size > 1600000) {
        //console.log("file too big! Should be 1.5MB or less ",event.target.files[0].size )
        console.log("resize image too large, much wow");
        event.target.value = "";
        setSelectedFile({});
        //  setSelectedFileThumbnail({});
      } else {
        //if the main image is within size constraints, set it as ready for transfer.  Also create a smaller thumbnail
        console.log("check ini file size now2", file);
        const resizedImage2: any = await resizeFile(file, 40, 200, 200);
        var newBlob2 = dataURIToBlob(resizedImage2);
        // a blob is almost a file, it's just missing a couple properties (leaving out lastModified auto gives it the curr time it seems)
        // add a few metadata items to make blob into file
        var newFile2 = new File([newBlob2], file.name, { type: "image/jpeg" });
        console.log("new and improved newFile?2", newFile2);

        //set aka save the updated selected file
        setSelectedFile(newFile);
        //  setSelectedFileThumbnail( newFile2 );
        setStatusMsg("");
      }

      //setSelectedFile( event.target.files[0] );
    } catch (err) {
      console.log(err);
      console.log("image fail");
      event.target.value = "";
      setSelectedFile({});
      //  setSelectedFileThumbnail({});
    }
  };

  // image resizer, do this prior to upload so we don't get crazy sizes
  /* 

    Resizer.imageFileResizer(
        file, // Is the file of the image which will resized.
        maxWidth, // Is the maxWidth of the resized new image.
        maxHeight, // Is the maxHeight of the resized new image.
        compressFormat, // Is the compressFormat of the resized new image.

        quality, // Is the quality of the resized new image. 1-100
        rotation, // Is the degree of clockwise rotation to apply to uploaded image. 
        responseUriFunc,  // Is the callBack function of the resized new image URI.
        outputType,  // Is the output type of the resized new image.
        minWidth, // Is the minWidth of the resized new image.
        minHeight, // Is the minHeight of the resized new image.
        );
    */

  // looks to beautifully maintain the ratio of the picture
  // for the max, looks to see if the highest width or height is above 1000, if so, reduces the highest value to 1000,
  //   and with the ratio scales the other dimension accordingly
  const resizeFile = (
    file: File,
    quality: number,
    maxWidth: number,
    maxHeight: number
  ) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        "JPEG",
        quality,
        0,
        (uri) => {
          console.log("what is the url before resolve:", uri);
          resolve(uri);
        },
        "base64",
        100,
        100
      );
    });

  //use to convert the base64 string back to a blob  File format
  const dataURIToBlob = (dataURI: any) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  var onClickHandlerPic = (formValues: any) => {
    //if alreeady uploading another file, prevent
    if (isUploading) {
      return;
    }

    setStatusMsg("processing...");
    setIsUploading(true);

    console.log("formValues", formValues);
    let theName = "";
    if (formValues.name !== "") theName = formValues.name;

    //console.log("on click handler, sel file", selectedFile)

    // Need to NOT have headers for some reason, then it works
    //		var myHeaders = new Headers();
    //		myHeaders.append("Content-Type", "application/json");

    var formdata = new FormData();
    formdata.append("file", selectedFile);

    formdata.append("key", uploadKey);
    formdata.append("keyId", formValues.keyCategories);
    formdata.append("name", theName);
    formdata.append("location", formValues.location);
    formdata.append("weight", formValues.weight);
    formdata.append("price", formValues.price);
    formdata.append("rating", formValues.rating);
    formdata.append("pro", formValues.pro);
    formdata.append("con", formValues.con);

    //console.log("new corrected sequelize on click handler, form data", formdata)

    // Need to NOT have headers for some reason, then it works. Otherwise get a false cors and typeerror message
    var requestOptions = {
      method: "POST",
      body: formdata,
    };

    fetch(`${constants.baseURL}/api/file/upload`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("did the new method work??", result);

        //update the member, this contains the same data, but should start a refresh of images and image count
        cbRefreshMemberPicState();
        setIsUploading(false);
        setSelectedFile({});
        setStatusMsg("Picture Upload complete.");
      })
      .catch((error) => {
        console.log("app json or did it error:", error);
        setIsUploading(false);
        setStatusMsg("error");
      });
  };

  var onClickEditPic = (formValues: any) => {
    //if alreeady uploading another file, prevent
    if (isUploading) {
      return;
    }

    setStatusMsg("processing edit...");
    setIsUploading(true);

    console.log(
      "formValues edit",
      formValues,
      "and edit id",
      formValues.id,
      formValues.name
    );
    let theName = "";
    if (formValues.name !== "") theName = formValues.name;

    //console.log("on click handler, sel file", selectedFile)

    // Need to NOT have headers for some reason, then it works
    //		var myHeaders = new Headers();
    //		myHeaders.append("Content-Type", "application/json");
    /*
                var formdata = new FormData(); 
        
                formdata.append("fileId", formValues.id)        
                formdata.append("key", uploadKey)
                formdata.append("keyId", formValues.keyCategories)
                formdata.append("name", theName) 
                formdata.append("location", formValues.location) 
                formdata.append("weight", formValues.weight) 
                formdata.append("price", formValues.price) 
                formdata.append("rating", formValues.rating) 
                formdata.append("pro", formValues.pro) 
                formdata.append("con", formValues.con) 
         
                //console.log("new corrected sequelize on click handler, form data", formdata) 
        
                // Need to NOT have headers for some reason, then it works. Otherwise get a false cors and typeerror message
                var requestOptions = {
                method: 'POST', 
                body: formdata 
                };
                */

    fetch(`${constants.baseURL}/api/file/pic/edit`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fileId: formValues.id,
        key: uploadKey,
        keyId: formValues.keyCategories,
        name: theName,
        location: formValues.location,
        weight: formValues.weight,
        price: formValues.price,
        rating: formValues.rating,
        pro: formValues.pro,
        con: formValues.con,
      }),
    })
      .then((response) => response.text())
      .then((result) => {
        console.log("did the new edit method work??", result);

        //update the member, this contains the same data, but should start a refresh of images and image count
        cbRefreshMemberPicState();
        setIsUploading(false);
        //  setSelectedFile({});
        setStatusMsg("Picture Edit complete.");
      })
      .catch((error) => {
        console.log("app json or did it error:", error);
        setIsUploading(false);
        setStatusMsg("error");
      });
  };

  return (
    <div>
      {uploadCurr >= uploadLimit ? (
        "[ You cannot upload more than 3 pictures at this time ]"
      ) : (
        <div className="form-group container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div
                className="card"
                style={{ borderLeft: "12px solid", borderColor: "green" }}
              >
                <div className="card-body">
                  <h5 className="card-title">
                    Picture Upload to {uploadDestination}
                  </h5>
                  <p className="card-text">
                    <input
                      type="file"
                      name="fileUploader"
                      onChange={onChangeHandlerPic}
                    />
                  </p>
                  <hr />

                  <Formik
                    initialValues={{
                      id: -1,
                      keyCategories: "",
                      name: "",
                      location: "",
                      weight: 12,
                      price: 3.0,
                      rating: 50,
                      pro: ``,
                      con: ``,
                    }}
                    validationSchema={Yup.object().shape({
                      id: Yup.number(),
                      keyCategories: Yup.string()
                        .min(3, "too short!")
                        .max(255, "Too Long!")
                        .required("Required"),
                      name: Yup.string().max(255, "Too Long!"),
                      location: Yup.string().max(255, "Too Long!"),
                      weight: Yup.number(),
                      price: Yup.number().required("Required"),
                      rating: Yup.number().required("Required"),
                      pro: Yup.string().max(255, "Too Long!"),
                      con: Yup.string().max(255, "Too Long!"),
                    })}
                    onSubmit={(values) => {
                      // same shape as initial values
                      //get topic id based off of topic name
                      //let theContent = values.templateText
                      if (values.id < 0) onClickHandlerPic(values);
                      else onClickEditPic(values);
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <b>File Stats</b>
                        <div className="form-group container-fluid">
                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="id">
                                Pic Id (keep -1 for new entry):
                              </label>
                              <Field
                                name="id"
                                type="text"
                                style={{ width: "90px" }}
                                className={
                                  "form-control" +
                                  (errors.id && touched.id ? " is-invalid" : "")
                                }
                              />
                            </div>

                            <div className="col-12">
                              <label htmlFor="keyCategories">
                                Pic Categories (sorted alpha, list i.e.
                                soup,ramen or clothes,business,shirt):
                              </label>
                              <Field
                                name="keyCategories"
                                type="text"
                                style={{ minWidth: "200px", maxWidth: "280px" }}
                                className={
                                  "form-control" +
                                  (errors.keyCategories && touched.keyCategories
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </div>

                            <div className="col-12">
                              <label htmlFor="name">Name:</label>
                              <Field
                                name="name"
                                type="text"
                                style={{ minWidth: "200px", maxWidth: "280px" }}
                                className={
                                  "form-control" +
                                  (errors.name && touched.name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </div>

                            <div className="col-12">
                              <label htmlFor="location">Location:</label>
                              <Field
                                name="location"
                                type="text"
                                style={{ minWidth: "200px", maxWidth: "280px" }}
                                className={
                                  "form-control" +
                                  (errors.location && touched.location
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="weight" className="text-left">
                                Weight
                              </label>
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Field
                                  name="weight"
                                  type="text"
                                  autoComplete=""
                                  style={{ fontSize: "24px", width: "90px" }}
                                  className={
                                    "form-control" +
                                    (errors.weight && touched.weight
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                oz
                              </div>
                            </div>
                            <div className="col-12">
                              <Field
                                name="weight"
                                type="range"
                                className="form-range"
                                min="3"
                                max="15"
                                step="0.2"
                                style={{
                                  marginLeft: "15px",
                                  minWidth: "1270px",
                                }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="price" className="text-left">
                                Price
                              </label>
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                $
                                <Field
                                  name="price"
                                  type="text"
                                  autoComplete=""
                                  style={{ fontSize: "24px", width: "90px" }}
                                  className={
                                    "form-control" +
                                    (errors.price && touched.price
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <Field
                                name="price"
                                type="range"
                                className="form-range"
                                min="1"
                                max="25"
                                step="0.5"
                                style={{
                                  marginLeft: "15px",
                                  minWidth: "1270px",
                                }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="rating" className="text-left">
                                Rating
                              </label>
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Field
                                  name="rating"
                                  type="text"
                                  autoComplete=""
                                  style={{ fontSize: "24px", width: "90px" }}
                                  className={
                                    "form-control" +
                                    (errors.rating && touched.rating
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <Field
                                name="rating"
                                type="range"
                                className="form-range"
                                min="0"
                                max="100"
                                step="2"
                                style={{
                                  marginLeft: "15px",
                                  minWidth: "1270px",
                                }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="pro">Pros:</label>
                              <Field
                                name="pro"
                                style={{ minWidth: "200px", maxWidth: "400px" }}
                                component="textarea"
                                rows="2"
                                className={
                                  "form-control" +
                                  (errors.pro && touched.pro
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="con">Cons:</label>
                              <Field
                                name="con"
                                style={{ minWidth: "200px", maxWidth: "400px" }}
                                component="textarea"
                                rows="2"
                                className={
                                  "form-control" +
                                  (errors.con && touched.con
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div
                          className="form-group "
                          style={{ textAlign: "center" }}
                        >
                          <button
                            type="submit"
                            className="btn btn-success btn-block"
                            style={{ maxWidth: "220px", marginTop: "10px" }}
                            disabled={
                              isUploading ||
                              (selectedFile.constructor === Object &&
                                values.id < 0)
                            }
                          >
                            {" "}
                            {values.id > 0 ? "Edit" : "Upload"} Picture
                          </button>
                        </div>

                        <b>{statusMsg !== "" ? statusMsg : ""}</b>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PicUploadWFields;
