// version 240112 1328 made notiftoqueue async for .then()
//import { lazy } from "react";
//import { Pathname } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function ReplaceAll(inputStr: string, find: string, replace: string) {
  if (inputStr === "") return inputStr;
  if (find === "") return inputStr;
  if (find === replace) return inputStr;

  //updated to arr to ensure we hit all instances, and no issues with replace values containing a char from find
  let strArr = inputStr.split(find);
  let strOutput = "";
  strArr.forEach((e, indx: number) => {
    strOutput += strArr[indx] + replace;
  });

  /*
        //has a flaw, if replacing say " with "" it will go indefinitely
        while (inputStr.indexOf(find) > -1) {
            inputStr = inputStr.replace(find, replace);
        }
    */

  return strOutput.substring(0, strOutput.length - replace.length);
}
function UnifyLineEndings(inputStr: string): string {
  let tempStr = inputStr;
  //first trim, replace \r\n with \r, and then replace \n with \r, then separate all items by \r
  // can do it as something like strArr = _inputString.Trim().Replace("\r\n", "\r").Replace("\n", "\r").Split('\r');
  // but broke it apart for easier future debug.  Plus I want to filter a few more things

  //tempStr = tempStr.replace("\r\n", "\n")
  //tempStr = tempStr.replace("\r", "\n")
  tempStr = ReplaceAll(tempStr, "\r\n", "\n");
  tempStr = ReplaceAll(tempStr, "\r", "\n");
  return tempStr;
}

function SortEachLine(strInput: string) {
  //parse by newline, removing lagging whitespace.  Assume left side could be formatted whitespace for formatting.
  let strArr = UnifyLineEndings(strInput).split("\n");
  let strOutput = "";

  //sort each line
  strArr.sort();

  //spit it back to a string, ignore newline on very first item
  strArr.forEach((line: String, index) => {
    strOutput += (index === 0 ? "" : "\n") + line;
  });
  return strOutput;
}

function CharCount(strInput: string) {
  return strInput.length;
}

function LineCount(strInput: string) {
  return UnifyLineEndings(strInput).split("\n").length;
}

function isNumeric(input: any) {
  return !isNaN(parseFloat(input)) && isFinite(input);
}

function IsJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    //console.log("the err is:", e);
    return false;
  }
  return true;
}

function IsEmptyJsonObj(theObj: any) {
  return Object.keys(theObj).length == 0;
}

function GetJsonObjNumKeys(theObj: any) {
  return Object.keys(theObj).length;
}

function PieceLength(strInput: string, delim: string) {
  if (strInput.trim() == "") return 1;

  let tempArr = strInput.split(delim);
  return tempArr.length;
}

//note, don't think we can overload functions. So NEED to manually input end piece value, else it'll just get to the end. I.e. for piece 1, need Piece(txt.delim,1,1)
function Piece(
  strInput: string,
  delim: string,
  pieceStart: number = 1,
  pieceEnd: number = 999
) {
  if (pieceStart < 1) pieceStart = 1;
  if (pieceEnd < pieceStart) pieceEnd = pieceStart;

  let tempArr = strInput.split(delim);
  if (pieceStart > tempArr.length) return "";

  if (pieceEnd > tempArr.length) pieceEnd = tempArr.length;

  //first piece put it in, should always be minimally = 1. Zero indexed
  let strOutput = tempArr[pieceStart - 1];
  //console.log("testtemparr", tempArr, "and starting___", strOutput)
  //start loop on piece 2
  for (let i = pieceStart; i < pieceEnd; i++) {
    strOutput += delim + tempArr[i];
  }
  return strOutput;
}
function PieceInject(
  strInput: string,
  delim: string,
  pieceStart: number = 1,
  pieceEnd: number = 999,
  delimInject: string = "\\",
  pieceInject: number = 1
) {
  //what is this doing exactly? appears to interject something at a specific piece

  if (pieceStart < 1) pieceStart = 1;
  if (pieceEnd < pieceStart) pieceEnd = pieceStart;
  //can be out of range if we don't want to replace anything? But if so, we wouldn't use this function//if (pieceInject > pieceEnd) pieceInject = pieceEnd

  let tempArr = strInput.split(delim);
  //this needed in inject function?// if (pieceStart > tempArr.length) return ""

  if (pieceEnd > tempArr.length) pieceEnd = tempArr.length;

  //first piece put it in, should always be minimally = 1. Zero indexed
  let strOutput = tempArr[pieceStart - 1];
  //console.log("testtemparr", tempArr, "and starting2___", strOutput)
  //start loop on piece 2
  for (let i = pieceStart; i < pieceEnd; i++) {
    if (i == pieceInject) {
      //if we're on the special specific piece
      strOutput += delimInject + tempArr[i];
    } else {
      strOutput += delim + tempArr[i];
    }
  }
  return strOutput;
}
function LinesPiecedOut(
  strInput: string,
  delim: string,
  pieceStart: number,
  pieceEnd: number
) {
  // quotes ~shouldn't matter unless you have a space in the input, but be conservative and add it to all
  //
  //pending
  //parse by newline, removing lagging whitespace.  Assume left side could be formatted whitespace for formatting.
  let strArr = UnifyLineEndings(strInput).split("\n");
  let lineFormatted = "";
  let strOutput = "";

  strArr.forEach((line: string) => {
    //in csv, a " in the string becomes "" to delimit
    lineFormatted = Piece(line, delim, pieceStart, pieceEnd);
    strOutput += "" + lineFormatted + "\n";
  });
  return strOutput.substr(0, strOutput.length - 1);
}
function LinesPiecedOutInject(
  strInput: string,
  delim: string,
  pieceStart: number,
  pieceEnd: number,
  delimInject: string,
  pieceInject: number
) {
  // quotes ~shouldn't matter unless you have a space in the input, but be conservative and add it to all
  //
  //pending
  //parse by newline, removing lagging whitespace.  Assume left side could be formatted whitespace for formatting.
  let strArr = UnifyLineEndings(strInput).split("\n");
  let lineFormatted = "";
  let strOutput = "";

  strArr.forEach((line: string) => {
    //in csv, a " in the string becomes "" to delimit
    lineFormatted = PieceInject(
      line,
      delim,
      pieceStart,
      pieceEnd,
      delimInject,
      pieceInject
    );
    strOutput += "" + lineFormatted + "\n";
  });
  return strOutput.substr(0, strOutput.length - 1);
}

//don't think this will help for syntax highlighers, as copying direct text is fine, but not highlighting and ctrl+c
function RemoveMultipleEmptyLines(strInput: string): string {
  //given a wall of text, check each line.
  // If a line is a date, change it to a unified date format
  let wasLineBeforeEmpty = false;
  let strOutput = "";

  let strArr = UnifyLineEndings(strInput).split("\n");
  strArr.forEach((line: string) => {
    if (line.trim() == "") {
      if (wasLineBeforeEmpty) {
        //if prev line was also empty, then skip adding line break
      } else {
        strOutput += "\n";
        wasLineBeforeEmpty = true;
      }
    } else {
      //if it wasn't empty, copy the line down and mark flag for next iteration
      strOutput += line + "\n";
      wasLineBeforeEmpty = false;
    }
  });

  return strOutput;
}

// // 122323 https://youtu.be/JU6sl_yyZqs?t=912
// function LazyLoad(path: any, namedExport: string | null = null) {
//   //to speed up processing, will lazily load import items, aka, won't load until it's needed.
//   // after loading, will be faster on subsequent access
//   // React has a way of managing this, need to use their lazy function, and then wrapping items that could be subject to lazy loading in Suspense tags, to say what elements should display on the initial lazy load
//   // by default it will take the default export from an imported file. If the item you are looking for is not the default,
//   // that is where the namedExport comes into play, if it exists, will load that as set it as the default instead for the code using it
//   //
//   //in theory this should work, oddly doesn't. Can't find path. Might be typescript issue
//   return lazy(() => {
//     const promise = import(path);

//     console.log("path", path, promise);
//     if (namedExport == null) {
//       console.log("prom1");
//       return promise;
//     } else {
//       console.log("prom2");

//       return promise.then((module) => ({ default: module[namedExport] }));
//     }
//   });
// }
function GenerateGuids(num: number): string {
  if (num < 1) return "";
  else {
    let output = "";
    for (let i = 0; i < num; i++) {
      output += uuidv4() + "\n";
    }
    return output.trim();
  }
}

const TranslateChatStatus = (message: string) => {
  switch (message) {
    case "none":
      return "None";
    case "read":
      return "Reading";
    case "music":
      return "Listening music/podcast";
    case "tv":
      return "Watching shows/movies";
    case "game":
      return "Gaming";
    case "work":
      return "Working / side projects";
    case "fhp":
      return "Let's collab (see my fhp profile)";
    case "chat":
      return "Here to chat";
    case "friends":
      return "Want friendship, only";
    case "single":
      return "Single and searching";
    case "marriednokids":
      return "Married, no kids";
    case "marriedwkids":
      return "Married, w kids";
    default:
      return "";
  }
};

//can be used to flatten to top level, i.e.
//let newObj2 = combineJsonObjects(newObj, newObj.content, "");
function combineJsonObjects(
  jsonObjOriginal: object,
  jsonObjNew: any,
  jsonObjPrefix: string
) {
  let jsonObjOutput: any = { ...jsonObjOriginal };

  for (let key in jsonObjNew) {
    if (jsonObjNew.hasOwnProperty(key)) {
      let prefixedKey = jsonObjPrefix + key;
      jsonObjOutput[prefixedKey] = jsonObjNew[key];
    }
  }

  return jsonObjOutput;
}

function ellipsisTruncateText(text: string, maxLength: number) {
  //but, don't want the adding of ... to make it longer than what it would've been by itself
  // i.e. if string is 51chars max of 50, then 50+... = 53. So truncate maxLength-3, to get charLength of max
  if (text.length > maxLength) return text.slice(0, maxLength - 3) + "...";
  else return text;
}

//compared to current date
function getDayDiff(datetimeInput: Date) {
  const currentDate: any = new Date();
  const targetDate: any = new Date(datetimeInput);

  // Calculate the difference in milliseconds
  const differenceInMs = targetDate - currentDate;

  // Calculate days, hours, and minutes
  //estimate days only iff it's greater than 2 days = 172 800 000 ms
  const daysDifference = differenceInMs / 1000 / 60 / (60 * 24);

  if (Math.abs(daysDifference) > 2)
    daysDifference < 0 ? Math.ceil(daysDifference) : Math.floor(daysDifference);

  //Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  // console.log(
  //   "chk ms",
  //   differenceInMs,
  //   "days check",
  //   daysDifference,
  //   "date input",
  //   datetimeInput
  // );

  //don't ceil/floor estimate these? Assume we'd only use them if < 2 anyway, so we want the fine details
  const hoursDifference =
    differenceInMs < 0
      ? Math.ceil(differenceInMs / (1000 * 60 * 60))
      : Math.floor(differenceInMs / (1000 * 60 * 60));
  const minutesDifference =
    differenceInMs < 0
      ? Math.ceil(differenceInMs / (1000 * 60))
      : Math.floor(differenceInMs / (1000 * 60));

  if (Math.abs(daysDifference) >= 2) {
    return `${Math.round(Math.abs(daysDifference))} days ${
      daysDifference > 0 ? "away" : "ago"
    }`;
  } else if (Math.abs(hoursDifference) >= 2) {
    return `${Math.round(Math.abs(hoursDifference))} hours ${
      hoursDifference > 0 ? "away" : "ago"
    }`;
  } else if (Math.round(Math.abs(minutesDifference)) >= 2) {
    return `${Math.round(Math.abs(minutesDifference))} minutes
     ${minutesDifference > 0 ? "away" : "ago"}`;
  } else return "now";

  // // Example usage
  // const givenDate1 = new Date('2023-10-05T07:00:00'); // More than 48 hours away
  // const givenDate2 = new Date(Date.now() + 1000 * 60 * 60); // 1 hour away
  // const givenDate3 = new Date(Date.now() + 1000 * 60); // 1 minute away

  // console.log(calculateTimeDifference(givenDate1)); // Output: "2 days away"
  // console.log(calculateTimeDifference(givenDate2)); // Output: "1 hours away"
  // console.log(calculateTimeDifference(givenDate3)); // Output: "1 minutes aw

  // //now my stuff
  //   //var datetime2 = typeof datetime !== 'undefined' ? datetime : new Date();

  //   var datetime2 = new Date(datetimeInput).getTime();
  //   var now = new Date().getTime();

  //   if (isNaN(datetime2)) {
  //     return "";
  //   }

  //   //console.log( datetime2 + " " + now);
  //   var millisec_diff = 0;
  //   millisec_diff = datetime2 - now;
  //   // if (datetime2 < now) {
  //   //   milisec_diff = now - datetime2;
  //   // } else {
  //   //   milisec_diff = datetime2 - now;
  //   // }

  //   var days =
  //     millisec_diff < 0
  //       ? Math.ceil(millisec_diff / 1000 / 60 / (60 * 24))
  //       : Math.floor(millisec_diff / 1000 / 60 / (60 * 24));

  //   console.log("chk ms", millisec_diff, "days check", days);
  //   //var date_diff = new Date( milisec_diff );

  //   // return days + " Days "+ date_diff.getHours() + " Hours " + date_diff.getMinutes() + " Minutes " + date_diff.getSeconds() + " Seconds";
  //   if (days === 0) return "Within 24 hours";
  //   else if (days > 0) return days + ` Days from now`;
  //   else return Math.abs(days) + " Days ago";
}

//cookie functions
function setCookie(name: string, value: string, hours: number) {
  const expires = new Date();
  // Calculate the expiration time by adding x hours (in milliseconds) to the current time
  expires.setTime(expires.getTime() + hours * 60 * 60 * 1000);
  //    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);

  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/; samesite=lax`;
}
function getCookie(name: string) {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();

    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }

  return null;
}
function deleteCookie(name: string) {
  const expires = new Date("2000-01-01"); // Set the expiration date in the past
  document.cookie = `${name}=;expires=${expires.toUTCString()};path=/; samesite=lax`;
}

const SendNotifToQueue = async (
  newContentObj: any,
  queueKey = "" as string,
  passKey = "" as string
) => {
  //flexdb send a message to a queue based on guid key. Defaultly to admin. Later, specific users.
  // Returns status true for expected success, false for failure

  //sample input obj
  // let newContentObj = {
  //   app: "talking-point",
  //   app_trigger: "user flagged mature or block",
  //   person_key: "new fangled notif method",
  //   from_cookie: "unknown",
  //   itemCategory: dispType,
  //   itemId: topicId,
  //   content: content,
  // };

  //  console.log("common funcs - msg testing comn0");

  try {
    if (!IsEmptyJsonObj(newContentObj)) {
      if (queueKey == "") queueKey = "fd82ac9e-9d1b-47e6-ba52-9d35c5546e48";
      if (passKey == "")
        passKey = "gr9jaiFA0Tnever ervr everwk20jgwGSDG lweG E3";

      //to content object itself, add info on where it's from
      newContentObj.sendSource = "tncommon";

      const response: any = await fetch("https://flexdbapi.azfriend.us/flex", {
        method: "put",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          dataName: "admin_queue",
          dataAccessKey: queueKey,
          passkey: passKey,
          //        rowId: 0,
          data: JSON.stringify(newContentObj),
        }),
      })
        .then((response) => response.json())
        .then((resp) => {
          //console.log("msg testing comn", newContentObj, resp);
          return resp;
          //if you return here, alters response var! //return 1;
        })
        .catch((err) => {
          //        console.log("msg test comn err", err);
          return -2;
        });

      // should be returning row_id
      // oddly been returning numbers like 1195 instead of 5632
      console.log(`response check`, response);
      if (response > 0) {
        // HTTP status within the range of 200 to 299 indicates success
        return 2;
      } else {
        // Handle non-success status (e.g., display an error message)
        //    console.log(`HTTP error! Status: ${response.status}`);
        return -3;
      }

      // end if emptyjson
    } else {
      return -4;
    }

    //end Try
  } catch (error: any) {
    // Handle network or other errors
    //    console.error("Error during fetch:", error.message);
    return -5;
  }
};

export {
  CharCount,
  LineCount,
  ReplaceAll,
  SortEachLine,
  UnifyLineEndings,
  RemoveMultipleEmptyLines,
  isNumeric,
  IsJsonString,
  IsEmptyJsonObj,
  GetJsonObjNumKeys,
  Piece,
  PieceLength,
  PieceInject,
  LinesPiecedOut,
  LinesPiecedOutInject,
  GenerateGuids,
  TranslateChatStatus,
  combineJsonObjects,
  ellipsisTruncateText,
  getDayDiff,
  getCookie,
  setCookie,
  deleteCookie,
  SendNotifToQueue,
  //  LazyLoad,
};
