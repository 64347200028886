import React from "react";
import Image from "react-bootstrap/Image";
import constants from "./constants";

const ImageContainerCardEdit: React.FC<any> = (props) => {
  const {
    imgId,
    imgName,
    picBase64,
    isEditable,
    cbLoadToEdit,
    cbRefreshOnDelete,
    status,
  } = props;

  // userIdViewing is the id of the user who is viewing
  // if null, assume you have 0 star permissions

  //fill out defaults if not inserted in props
  let isEditable1 = isEditable ? isEditable : false;
  let status1 = status ? status : "not specified";

  //
  function cbLoadToEdit1() {
    //load this specific image into field values for editing
    cbLoadToEdit();
  }

  function deleteImage(theFileId: number, theFileName: Text) {
    fetch(`${constants.baseURL}/api/file/pic/delete`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fileId: theFileId,
        fileName: theFileName,
      }),
    })
      .then((response) => response.json())
      //according to the API we made, the data returned will be the user this time, so for clarification change var data below to user
      .then((returnData) => {
        //add conditional to prevent login if wrong password, based on response message sent back
        if (returnData) {
          //console.log("success?? del maybe?")
          //setStatusMsg("<< List updated >>")
          if (cbRefreshOnDelete != null) cbRefreshOnDelete();
        } else console.log("fail?? del maybe");
        //setStatusMsg("<< list failed to update >>")
      });
  }

  //note, the droppableId must match the name we give to the state cols
  return (
    <div>
      <Image src={`data:image/jpeg;base64,${picBase64}`} rounded fluid />
      {isEditable1 ? (
        <div
          style={{
            textAlign: "left",
            border: "4px solid green",
            marginBottom: "20px",
          }}
        >
          {status1 === "pending" ? (
            <div style={{ color: "red" }}>This picture is pending approval</div>
          ) : status1 === "rejected" ? (
            <div style={{ color: "red" }}>This picture has been rejected.</div>
          ) : (
            ""
          )}

          <button
            className="ml-2"
            onClick={() => {
              deleteImage(imgId, imgName);
            }}
          >
            Delete this entry
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ImageContainerCardEdit;
