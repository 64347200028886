/*EDIT HISTORY:
 TO DO:  when switching topics, it is delayed in the prev topic per the refresh.
		 fix sources to display, determine how to store.  
		 req a param to be able to contribute, else hide that tab? either completely, or per media topics, view vs view and contrib? 
	  add another option to maint to ignore confirmed adult/ban entries? Prb is could have one and not the other.
	  fix sticky bar to draw on top of other elements
	
	  -add a category of useful trivia? like the survival rule of 3's, states and capitals?
 
 231223 TSN separated out into separate pages to more easily manage
 231222 TSN readjusted headers to fit better for screen sizes
 ...
 added tailwind, sized for mobile
 ...
 200601c TSN implemented EasyStringReplace
 200527  TSN add utilities tab for Json sort and easy string formatter
 200526  TSN add random letter and restructure randomizing code and add minimum value
 200421  TSN add another category for foreign languages
 200215  TSN add display of user who added, and date added; add formik yup check to prevent * in user who entered field;
	   b     add moderator permission to auto verify entries;
	   c     add adult/flag moderate change options!
	   d     expand rand numbs to 10; ~sort of working sticky navbar on moderate;
 200214  TSN updated layout and spacing of when splits to two media items; made all rand numbs give five numbs;
 200122  TSN start changing UI with Bootstrap
 200119  TSN modify to add a rand numb generator; changed 'adult' to 'mature'; allow memes for all;
 200109  TSN modify to defaultly prevent showing any adult content, and also to prevent
			 showing media topics, via url params. Also added more flexibility in youtube link input.
 200107  TSN made it so that the youtube videos and pics are embedded 
 200105  TSN added media links (YT and meme), renamed some tables, added rating system, adjusted UI looks
 200104  TSN added connection to SQL, ability to read and write data
 191228  TSN started
*/

// add Suspense for lazy loading
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import moment from "moment";

import constants from "../components/constants";
import { SendNotifToQueue } from "../components/commonFunctions";
import Button from "../components/deltapeng/tailwind/Button";
import PicList from "../components/PicList";

// replace with lazy loading 231223
//technically this isn't being lazy loading here, since it's in tab bars it all loads with page
// Instead needs to be at App level, for different url routes
// but for practice, this is how it should work
//
//import RandomItemGen from "./RandomItemGen";
//import TabUtilities from "./TabUtilities";
//import BibleStudy from "./BibleStudy";
//import Links from "./Links";
//import AddNewTalk from "./AddNewTalk";

//import { LazyLoad } from "../components/commonFunctions";
const RandomItemGen = lazy(() => import("./RandomItemGen")); //const RandomItemGen = LazyLoad("../pages/RandomItemGen");
const TabUtilities = lazy(() => import("./TabUtilities")); //const TabUtilities = LazyLoad("../pages/TabUtilities");
const BibleStudy = lazy(() => import("./BibleStudy")); //const BibleStudy = LazyLoad("../pages/BibleStudy");
const Links = lazy(() => import("./Links")); //const Links = LazyLoad("../pages/Links");
const AddNewTalk = lazy(() => import("./AddNewTalk")); //const AddNewTalk = LazyLoad("../pages/AddNewTalk");

//const Talk: React.FC<RouteComponentProps> = () => {
const Talk: React.FC<any> = (props) => {
  const { dispWidth, isMobile } = props;

  let { code = "" } = useParams();

  //get router params
  let allowURLParam = code;
  let allowMedia = false;
  let allowAdult = false;
  let allowModerator = false;

  //check if param exists, if it does, include options if it contains specific text
  if (allowURLParam) {
    allowMedia = allowURLParam.includes("medi");
    allowAdult = allowURLParam.includes("sens");
    allowModerator = allowURLParam.includes("mder");

    //for fun, reblock if it was true, but then a string is longer than it was supposed to be to prevent random guessers
    //   media or sensi... or sensa...
    if (allowMedia) allowMedia = !allowURLParam.includes("media");
    if (allowAdult)
      allowAdult = !(
        allowURLParam.includes("sensi") ||
        allowURLParam.includes("sensa" || allowURLParam.includes("adult"))
      );
    if (allowModerator)
      allowModerator = !(
        allowURLParam.includes("mod") || allowURLParam.includes("admin")
      );
  }

  //need loaded data to be State, so that when it updates it notifies and refreshes the UI
  const [isLoaded, setIsLoaded] = useState(false);
  const [dispType, setDispType] = useState(0);
  const [topicCount, setTopicCount] = useState(0);
  const [statusMsg, setStatusMsg] = useState("");
  const [dispEntries, setDispEntries] = useState([
    {
      id: -1,
      content:
        "Choose Talking Point topic, then press button to get random display entries.",
    },
  ]);
  const [windowCheck, setWindowCheck] = useState(false);

  const [isLoadedFlag, setIsLoadedFlag] = useState(false);
  const [topicDataFlag, setTopicDataFlag] = useState([]);

  const [talkserverIp, setTalkserverIp] = useState("");

  //array state, where data is an array of objects of topic data
  const [topicData, setTopicData] = useState([
    { id: 1, data: [] },
    { id: 2, data: [] },
    { id: 3, data: [] },
    { id: 4, data: [] },
    { id: 5, data: [] },
    { id: 6, data: [] },
    { id: 7, data: [] },
    { id: 8, data: [] },
    { id: 9, data: [] },
    { id: 10, data: [] },
    { id: 11, data: [] },
    { id: 12, data: [] },
    { id: 13, data: [] },
  ]);

  //add helper function to update state, being able to add data by topic id
  //  (technically looks to copy prev other array data over as well)
  function addTopic(index: number, newData: any) {
    setTopicData((prevItems) => {
      return [
        ...prevItems.slice(0, index - 1),
        { ...prevItems[index - 1], data: newData },
        ...prevItems.slice(index),
      ];
    });
  }

  let topicOptions: any[] = [];

  if (allowMedia)
    topicOptions = [
      { name: "phrase", index: 1 },
      { name: "ponder", index: 2 },
      { name: "wyr", index: 3 },
      { name: "compliment", index: 4 },
      { name: "misc", index: 5 },
      { name: "word", index: 6 },
      { name: "charade", index: 7 },
      { name: "joke", index: 8 },
      { name: "riddle", index: 9 },
      { name: "foreign language", index: 13 },
      { name: "youtube music url", index: 10 },
      { name: "youtube video url", index: 11 },
      { name: "meme url", index: 12 },
    ];
  else
    topicOptions = [
      { name: "phrase", index: 1 },
      { name: "ponder", index: 2 },
      { name: "wyr", index: 3 },
      { name: "compliment", index: 4 },
      { name: "misc", index: 5 },
      { name: "word", index: 6 },
      { name: "charade", index: 7 },
      { name: "joke", index: 8 },
      { name: "riddle", index: 9 },
      { name: "foreign language", index: 13 },
    ];

  function getUniqRandNumbArr(
    rndNumsWanted: number,
    maxValue: number,
    minValue: number = 0
  ) {
    let result: number[] = [];
    //since you want unique numbers, if you want more numbers than max value....push min to maxValue
    // +1 as 0 is a value
    if (maxValue - minValue + 1 < rndNumsWanted) {
      for (let i = 0; i < maxValue; i++) {
        result.push(i);
      }
    } else {
      while (result.length < rndNumsWanted) {
        //no +1 since it's for zero based indices
        let number =
          Math.floor(Math.random() * (maxValue + 0.99 - minValue)) + minValue;
        if (!result.includes(number)) {
          result.push(number);
        }
      }
    }
    return result;
  }

  //need useEffect to transfer the result data into an array
  useEffect(() => {
    //fetch('http://localhost:3051/talk/get',
    fetch(`${constants.baseURL}/talk/get`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        talkCat: "",
        allowAd: allowAdult,
      }),
    })
      .then((response) => response.json())
      .then((topics) => {
        //keep it simple, pull back all data in one go
        //topics[i]
        for (let i = 0; i < topicOptions.length; i++) {
          //if not allowed to access media, you'll skip loading that media data
          //  since it won't be in topicOptions

          //put the filtered data by topic id, into the state index
          var filteredData = topics.filter((obj: any) => {
            return obj.type_id === topicOptions[i].index;
          });

          addTopic(topicOptions[i].index, filteredData);
          //setLoadedTopicData({ id: i, data: topics.});
        }

        //if we're at a point of loading, switch setDispType to 2 so that
        //useEffect is triggered, and starts with an initial data load of ponder
        setDispType(2);
        //sort and store data in separate arrays
        setIsLoaded(true);
      })
      .catch((err) => {
        //console.log(err)
        //btnSubmit0.disabled = false;
      });
  }, []);
  //if not needed, leave it as [], else it may refresh every time or otherwise affects loading

  //trigger auto refresh when disp type changes
  useEffect(() => {
    getRandTalk();
  }, [dispType]);

  // -- any useEffects() must occur above, and not below a conditional statement like below which could change order or rendering --------

  function getLengthTopic(topicIndx: number) {
    for (let i = 0; i < topicData.length; i++) {
      if (topicData[i].id === topicIndx) {
        return topicData[i].data.length;
      }
    }

    return 0;
  }
  function getStateIndex(topicIndx: number) {
    //get the state's index for the topic index
    for (let i = 0; i < topicData.length; i++) {
      if (topicData[i].id === topicIndx) {
        return i;
      }
    }
    return -1;
  }

  function getRandTalk() {
    if (dispType > 0) {
      let maxEntries = getLengthTopic(dispType);
      setTopicCount(maxEntries);

      let formattedResult: any[] = [
        {
          id: -1,
          content:
            "Choose Talking Point topic, then press button to get random display entries.",
        },
      ];

      if (maxEntries < 1)
        formattedResult = [
          {
            id: -1,
            content: "No data in this topic yet. Why not add some of your own?",
          },
        ];
      else {
        let randNumbsArr =
          dispType >= 8 && dispType <= 13
            ? getUniqRandNumbArr(6, maxEntries)
            : getUniqRandNumbArr(9, maxEntries);
        if (randNumbsArr.length > 0) {
          let stateIndx = getStateIndex(dispType);

          if (stateIndx == -1) {
            formattedResult = [
              { id: -1, content: "Error: Topic does not exist in state." },
            ];
          } else {
            formattedResult = [];

            for (let i = 0; i < randNumbsArr.length; i++) {
              let dataEntry: any = topicData[stateIndx].data[randNumbsArr[i]];

              // 220531 2029 for some reason I'm getting undefined on some entries, gives an error. So, go through array and push out any entry which has undefined
              if (dataEntry != undefined) formattedResult.push(dataEntry);
            }
          }
        }
      }

      setDispEntries(formattedResult);
    }
  }
  function submitRating(
    topicId: number,
    like: number,
    dislike: number,
    adult: number,
    flag: number,
    dispType = -1 as number,
    content: "",
    answer: ""
  ) {
    //flag as mature    submitRating(item.id, 0, 0, 2, 0);
    //flag to block    submitRating(item.id, 0, 0, 0, 2);

    //new code, send notif flexdb to appropriate queue
    let newContentObj = {
      app: "talking-point",
      app_trigger: "user flagged mature or block",
      person_key: "new fangled notif method",
      from_cookie: "unknown",
      itemCategory: dispType,
      itemId: topicId,
      content: content,
      answer: answer,
      shouldBeMature: adult,
      shouldBeBlocked: flag,
      date_resolved: "",
      actions: [
        "unmark mature talkpoint",
        "mark mature pending talkpoint",
        "mark mature confirmed talkpoint",
        "mark mature confirmedIsNot talkpoint",
        "unmark blocked talkpoint",
        "mark blocked pending talkpoint",
        "mark blocked confirmed talkpoint",
        "mark blocked confirmedIsNot talkpoint",
        "delete talkpoint",
      ],
    };
    SendNotifToQueue(newContentObj);

    // official flagging by user. Also still run this, to err on side of caution
    // so that if user flags, it will remove it from public list even before I get to it
    // I'll be notified, and I can later determine if it should be flagged or not
    //
    //tkId, tkLiked, tkDisliked, tkFlagged, tkUserWhoFlagged, tkForAdults
    fetch(`${constants.baseURL}/talk/userflag`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        passkey: "ec8b9328-73a2-4908-81af-be5b38c8c9c2",
        tkId: topicId,
        tkLiked: like,
        tkDisliked: dislike,
        tkFlagged: flag,
        tkUserWhoFlagged: "",
        tkForAdults: adult,
      }),
    })
      .then((response) => response.json())
      .then((resp) => {
        setStatusMsg("<< Talk rating totally happened, probably >>: " + resp);
      });
  }
  // function checkTalkServerIp() {
  //   fetch(`http://nameless-depths-16148.herokuapp.com/api/misc/get`, {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       name: "talkserverip",
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((resp) => {
  //       setStatusMsg("<< " + resp + " >>");
  //       setTalkserverIp(resp);
  //       return resp;
  //     })
  //     .catch((err) => {
  //       setStatusMsg("<< An error occurred: " + err + " >>");
  //     });

  //   setTalkserverIp("");
  //   return "unknown";
  // // }
  // function autoApproveItems() {
  //   fetch(`${constants.baseURL}/talk/approve`, {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       passkey: "no need",
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((resp) => {
  //       if (Array.isArray(resp))
  //         setStatusMsg(
  //           "<< Auto approved " +
  //             resp[0].rowCount +
  //             " DP entries and " +
  //             resp[1].rowCount +
  //             " unknown entries at " +
  //             Date() +
  //             ">>"
  //         );
  //       else setStatusMsg("<< " + resp + " >>");
  //     })
  //     .catch((err) => {
  //       setStatusMsg("<< An error occurred: " + err + " >>");
  //     });
  // }
  //gets flagged media entries
  function getFlag() {
    fetch(`${constants.baseURL}/talk/getflag/media`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        passkey: "fishsticks",
      }),
    })
      .then((response) => response.json())
      .then((topicsFlag) => {
        //keep it simple, pull back all data in one go
        //topics[i]
        setTopicDataFlag(topicsFlag);
        //sort and store data in separate arrays
        setIsLoadedFlag(true);
        setStatusMsg("<< Flag data checked: " + Date() + ">>");
      })
      .catch((err) => {
        setStatusMsg("<< An error occurred: " + err + " >>");
      });
  }
  function setFlag(id: number, flagVal: number, adultVal: number) {
    //tho originally this was admin code to mark it

    //new code, send notif flexdb to appropriate queue
    let newContentObj = {
      app: "talking-point",
      app_trigger: "setFlag admin",
      person_key: "new fangled notif method2",
      from_cookie: "unknown",
      itemCategory: dispType,
      itemId: id,
      flagVal: flagVal,
      adultVal: adultVal,
      date_resolved: "",
      actions: [
        "delete talkpoint",
        "set to neutral",
        "agree and adult",
        "agree and flag",
        "should be flagged no question",
        "should be adult no question",
      ],
    };
    SendNotifToQueue(newContentObj);

    //actual code. Move this to admin
    // fetch(`${constants.baseURL}/talk/setflag`, {
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     passkey: "zanybrain",
    //     tkId: id,
    //     tkFlagVal: flagVal,
    //     tkAdultVal: adultVal,
    //   }),
    // })
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((resp) => {
    //     if (resp.command === "UPDATE")
    //       setStatusMsg(
    //         "<< Flag / Mature value changed for id:" +
    //           id +
    //           " changed at " +
    //           Date() +
    //           ">>"
    //       );
    //     else
    //       setStatusMsg(
    //         "<< Flag / Mature change attempt, error occurred for " +
    //           id +
    //           ": " +
    //           resp +
    //           " >>"
    //       );
    //   })
    //   .catch((err) => {
    //     setStatusMsg("<< An error occurred: " + err + " >>");
    //   });
  }

  // -- function to check window size for...knowing when to start stretching media based on screen size rather than fixed size when smaller? -------------
  //combined with a state var to track when the change happens
  //  limited by if statements so that you don't infinite loop from refreshing
  // function myFunction(wmm: any) {
  //   if (wmm.matches) {
  //     // If media query matches
  //     if (!windowCheck) setWindowCheck(true);
  //   } else {
  //     if (windowCheck) setWindowCheck(false); //document.body.style.backgroundColor = "pink";
  //   }
  // }
  // let wmm = window.matchMedia("(max-width: 650px)");
  // myFunction(wmm); // Call listener function at run time
  // wmm.addListener(myFunction); // Attach listener function on state changes

  function IsJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  //don't display site if data is not loaded yet
  if (!isLoaded) {
    return (
      <div className="mx-3">
        <div className="my-1 progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-info"
            role="progressbar"
            style={{ width: "75%" }}
          ></div>
        </div>
        <p>Loading data... we get to practice patience. Woo hoo!!</p>
      </div>
    );
  }

  return (
    <div id="mainPage">
      <ul className="tabListItems nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link"
            id="randnum-tab"
            data-toggle="tab"
            href="#randnum"
            role="tab"
            aria-controls="randNum"
            aria-selected="true"
          >
            {isMobile || dispWidth < 930
              ? "Time and Random"
              : "Time and Random Item Generator"}
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link active"
            id="main-tab"
            data-toggle="tab"
            href="#main"
            role="tab"
            aria-controls="main"
            aria-selected="false"
          >
            Talking Points
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="addnew-tab"
            data-toggle="tab"
            href="#addnew"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            {isMobile || dispWidth < 930 ? "Add New" : "Add New Talking Point"}
          </a>
        </li>

        {!isMobile ? (
          <li className="nav-item">
            <a
              className="nav-link"
              id="utils-tab"
              data-toggle="tab"
              href="#utils"
              role="tab"
              aria-controls="utilstab"
              aria-selected="false"
            >
              Utility Functions
            </a>
          </li>
        ) : (
          ""
        )}

        <li className="nav-item">
          <a
            className="nav-link"
            id="bible-tab"
            data-toggle="tab"
            href="#bible"
            role="tab"
            aria-controls="bibletab"
            aria-selected="false"
          >
            Bible Study
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            id="links-tab"
            data-toggle="tab"
            href="#links"
            role="tab"
            aria-controls="linkstab"
            aria-selected="false"
          >
            Links
          </a>
        </li>

        {allowModerator ? (
          <React.Fragment>
            <li className="nav-item">
              <a
                className="nav-link"
                style={{
                  borderLeft: "8px solid green",
                  borderTop: "4px solid green",
                }}
                id="piclist-tab"
                data-toggle="tab"
                href="#piclist"
                role="tab"
                aria-controls="piclist"
                aria-selected="false"
              >
                PicLists
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                style={{
                  borderTop: "4px solid green",
                }}
                id="mod-tab"
                data-toggle="tab"
                href="#mod"
                role="tab"
                aria-controls="mod"
                aria-selected="false"
              >
                Maintenance
              </a>
            </li>
          </React.Fragment>
        ) : (
          ""
        )}
      </ul>

      <div className="tabcontentalignment my-3 mx-4">
        <div className="tab-content" id="TabContent">
          <div
            className="randItemsPage tab-pane fade mx-3"
            id="randnum"
            role="tabpanel"
            style={{ textAlign: "left" }}
            aria-labelledby="randnum-tab"
          >
            <Suspense fallback={<h3>Lazy Loading...</h3>}>
              <RandomItemGen dispWidth={dispWidth} isMobile={isMobile} />
            </Suspense>
          </div>

          <div
            className="talkingPointsPage tab-pane fade show active w-full"
            id="main"
            role="tabpanel"
            aria-labelledby="main-tab"
          >
            <h3>Talking Point Display:</h3>
            <Formik
              initialValues={{
                dropdownDispType: 2,
              }}
              onSubmit={(values) => {}}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form>
                  <div className="formikDropdown ml-2">
                    <label htmlFor="dropdownDispType">Topic Point type:</label>
                    <Field
                      as="select"
                      name="dropdownDispType"
                      className="border rounded p-2 ml-2"
                      onChange={(e: any) => {
                        //set state and update self
                        setDispType(parseInt(e.target.value, 0));
                        setFieldValue("dropdownDispType", e.target.value);
                      }}
                    >
                      {Array.from(topicOptions).map((item: any, indx: any) => (
                        <option key={item.name} value={item.index}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="dropdownDispType"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div className="mainArea mt-2">
              <Button
                variant="info"
                className="ml-2 mb-2"
                onClick={() => getRandTalk()}
              >
                Get Talking Points
              </Button>
              <br />
              <div className="ml-2">{`Total Talking points of this category: ${topicCount}`}</div>
              <hr />

              <div className="mainTopicPointCardsDisplay grid">
                {!dispEntries || dispType == 0 ? (
                  <div className="text-2xl ml-3 mb-4">No data.</div>
                ) : (
                  <div
                    className={
                      //for the media and larger items like joke/riddles, split in 2, otherwise 3 if screen larger
                      dispType >= 8 && dispType <= 13
                        ? `grid lg:grid-cols-2 mb-4  ${
                            dispWidth > 1500
                              ? "ml-40 mr-40"
                              : dispWidth > 1200
                              ? "ml-20 mr-20"
                              : ""
                          }`
                        : `grid lg:grid-cols-3 mb-4 ${
                            dispWidth > 1500
                              ? "ml-40 mr-40"
                              : dispWidth > 1200
                              ? "ml-20 mr-20"
                              : ""
                          }`
                    }
                  >
                    {Array.from(dispEntries).map((item: any, indx: any) => (
                      <div
                        className="card mt-3 lg:ml-3 lg:mr-3"
                        key={`dispEntries_${item.id}`}
                        style={{}}
                      >
                        {item.hasOwnProperty("adult") && item.adult > 0 ? (
                          <div style={{ color: "red" }}>Mature</div>
                        ) : (
                          ""
                        )}
                        <div className="card-body displaySection">
                          {
                            <div className="card-title">
                              {item.id > -1 ? `[id ${item.id}]: ` : ""}
                            </div>
                          }
                          {(dispType != 10 &&
                            dispType != 11 &&
                            dispType != 12) || //if it's not media item
                          item.id == -1 ? (
                            //handle foreign language items
                            item.type_id == 13 && IsJsonString(item.content) ? (
                              <div style={{ fontSize: "20px" }}>
                                <ul className="card-img-top">
                                  {Object.keys(JSON.parse(item.content)).map(
                                    (keyFL: any) => (
                                      <li
                                        key={`dispEntries_li_${keyFL.id}`}
                                      >{`${keyFL}: ${
                                        JSON.parse(item.content)[keyFL]
                                      }`}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ) : (
                              //handle non media, non foreign language item
                              <div
                                className="card-img-top"
                                style={{ fontSize: "28px" }}
                              >
                                {item.content}
                              </div>
                            )
                          ) : dispType == 10 || dispType == 11 ? (
                            <div className="card-img-top">
                              <a
                                href={item.content}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.content}
                              </a>
                              <br />
                              <div
                                className={
                                  windowCheck
                                    ? ""
                                    : "embed-responsive embed-responsive-16by9"
                                }
                              >
                                <iframe
                                  width="400"
                                  height="225"
                                  allowFullScreen
                                  src={`${item.content}`}
                                ></iframe>
                              </div>
                            </div>
                          ) : (
                            <div className="card-img-top">
                              <img
                                className={windowCheck ? "" : "card-img"}
                                style={{ maxWidth: "840px" }}
                                src={item.content}
                                alt={`pic from id:${item.id}`}
                              ></img>
                              <br />
                              <a
                                href={item.content}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Source
                              </a>
                            </div>
                          )}

                          {item.hasOwnProperty("answer") &&
                          item.answer !== "" ? (
                            <div className="card-text">
                              <Button
                                variant="secondary"
                                className="text-2xl mt-2"
                                onClick={() => {
                                  let x = document.getElementById(
                                    `dispEntriesAns_${item.id}`
                                  );
                                  if (x) {
                                    if (x.style.display === "none") {
                                      x.style.display = "block";
                                    } else {
                                      x.style.display = "none";
                                    }
                                  }
                                }}
                              >
                                {dispType != 10 &&
                                dispType != 11 &&
                                dispType != 12 ? (
                                  <div>Answer : </div>
                                ) : (
                                  <div>Description:</div>
                                )}
                                <div
                                  id={`dispEntriesAns_${item.id}`}
                                  style={{ display: "none" }}
                                >
                                  {item.answer}
                                </div>
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}

                          {/* <div className="card-footer">
                            {item.hasOwnProperty("source") &&
                            item.source !== "" &&
                            item.source !== "unknown" ? (
                              <div style={{ fontSize: "0.8em" }}>
                                Source: {item.source}
                              </div>
                            ) : (
                              ""
                            )}

                            {item.hasOwnProperty("user_entered") &&
                            item.user_entered !== "" ? (
                              <div style={{ fontSize: "0.8em" }}>
                                Contributed by: {item.user_entered}
                              </div>
                            ) : (
                              ""
                            )}

                            {item.hasOwnProperty("date_created") ? (
                              <div style={{ fontSize: "0.8em" }}>
                                Added:{" "}
                                {item.date_created
                                  .substring(0, 19)
                                  .replace("T", ", ")}{" "}
                                UTC
                              </div>
                            ) : (
                              ""
                            )}
                          </div> */}
                          <hr />
                          {item.id > -1 ? (
                            <div
                              id={`dispEntriesFeedback_${item.id}`}
                              style={{ display: "flex" }}
                            >
                              Optionally mark this talking point:
                              <br />
                              {item.adult === 0 ? (
                                <Button
                                  variant="warning"
                                  className="mt-1 ml-3"
                                  onClick={() => {
                                    let x = document.getElementById(
                                      `dispEntriesFeedback_${item.id}`
                                    );
                                    if (x) {
                                      x.style.display = "none";
                                    }
                                    submitRating(
                                      item.id,
                                      0,
                                      0,
                                      2,
                                      0,
                                      dispType,
                                      item.content,
                                      item.answer
                                    );
                                  }}
                                >
                                  Flag as 'Mature'
                                </Button>
                              ) : (
                                ""
                              )}
                              {item.flagged === 0 ? (
                                <div>
                                  <Button
                                    variant="danger"
                                    className="mt-1 ml-3"
                                    onClick={() => {
                                      let x = document.getElementById(
                                        `dispEntriesFeedback_${item.id}`
                                      );
                                      if (x) {
                                        x.style.display = "none";
                                      }
                                      submitRating(
                                        item.id,
                                        0,
                                        0,
                                        0,
                                        2,
                                        dispType,
                                        item.content,
                                        item.answer
                                      );
                                    }}
                                  >
                                    Flag to report
                                  </Button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Button
                variant="info"
                className="ml-2"
                onClick={() => getRandTalk()}
              >
                Get Talking Points
              </Button>
            </div>
          </div>

          <div
            className="addNewTalkingPoint tab-pane fade"
            id="addnew"
            role="tabpanel"
            aria-labelledby="addnew-tab"
          >
            <Suspense fallback={<h3>Lazy Loading...</h3>}>
              <AddNewTalk topicOptions={topicOptions} />
            </Suspense>
          </div>

          <div
            className="tab-pane fade"
            id="utils"
            role="tabpanel"
            aria-labelledby="utils-tab"
          >
            <h3>Utility Functions:</h3>
            <Suspense fallback={<h3>Lazy Loading...</h3>}>
              <TabUtilities />
            </Suspense>
          </div>

          <div
            className="tab-pane fade"
            id="links"
            role="tabpanel"
            aria-labelledby="links-tab"
          >
            <Suspense fallback={<h3>Lazy Loading...</h3>}>
              <Links />
            </Suspense>
          </div>

          <div
            className="tab-pane fade"
            id="bible"
            role="tabpanel"
            aria-labelledby="bible-tab"
          >
            <div className="row">
              <div className="col-11">
                <h3>Bible Study:</h3>
                <Suspense fallback={<h3>Lazy Loading...</h3>}>
                  <BibleStudy />
                </Suspense>
              </div>
              <div className="col-1" />
            </div>
          </div>

          {allowModerator ? (
            <div
              className="tab-pane fade"
              id="piclist"
              role="tabpanel"
              aria-labelledby="piclist-tab"
            >
              <h3>PicLists:</h3>
              <PicList />
            </div>
          ) : (
            ""
          )}

          {allowModerator ? (
            <div
              className="tab-pane fade container-fluid"
              id="mod"
              role="tabpanel"
              style={{ textAlign: "left" }}
              aria-labelledby="mod-tab"
            >
              <h3>Maintenance:</h3>
              <div>
                <div className="row">
                  {/* 
                  <div style={{ marginLeft: "20px" }}>
                    <button
                      onClick={() => {
                        setStatusMsg("ip check...");
                        checkTalkServerIp();
                      }}
                    >
                      Check ip of talk server: {talkserverIp}
                    </button>
                  </div>
                  <br />
                  <a
                    style={{ marginLeft: "20px" }}
                    href="https://azfriend.us:8443"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Portainer check
                  </a>

                  */}
                </div>

                <br />

                <div
                  data-spy="scroll"
                  data-target=".navbar"
                  data-offset="50"
                  style={{ position: "relative", zIndex: 10000 }}
                >
                  <span style={{ fontSize: "24px" }}>
                    Media items to review: {topicDataFlag.length}
                  </span>

                  <div>Type ids: 10 ytMusic, 11 ytVid, 12 meme</div>
                  <br />
                  <nav
                    className="navbar navbar-expand-sm bg-secondary navbar-dark"
                    style={{
                      position: "sticky",
                      top: "0",
                    }}
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        Note: To actually change flags on items, talk to admin,
                        use id
                        <br />
                        <b className="ml-2">
                          {statusMsg !== "" ? statusMsg : ""}
                        </b>
                        <br />
                        <button
                          className="btn btn-info ml-2 mb-2"
                          onClick={() => getFlag()}
                        >
                          Load/Refresh Flagged Entries
                        </button>
                      </li>
                    </ul>
                  </nav>
                  <div
                    id="section1"
                    className="container-fluid"
                    style={{ paddingTop: "70px", paddingBottom: "70px" }}
                  >
                    <div className="row">
                      {!topicDataFlag ||
                      !isLoadedFlag ||
                      topicDataFlag.length === 0 ? (
                        <div className="container-fluid">No data.</div>
                      ) : (
                        Array.from(topicDataFlag).map(
                          (item: any, indx: any) => (
                            <div
                              className="card col-lg-6"
                              key={`dispEntriesFlag_${item.id}`}
                              style={
                                indx % 2 === 1
                                  ? { marginTop: "200px" }
                                  : { marginBottom: "100px" }
                              }
                            >
                              <div
                                style={{ display: "flex", fontSize: "24px" }}
                              >
                                <div style={{ color: "green" }}>
                                  Type Id: {item.type_id} /
                                </div>

                                {item.hasOwnProperty("flagged") ? (
                                  <div style={{ color: "orange" }}>
                                    Flagged val: {item.flagged} /
                                  </div>
                                ) : (
                                  "  "
                                )}
                                {item.hasOwnProperty("adult") &&
                                item.adult > 0 ? (
                                  <div style={{ color: "red" }}>
                                    Mature val: {item.adult}
                                  </div>
                                ) : (
                                  "  "
                                )}
                              </div>

                              <div className="card-body editSection">
                                {
                                  <div
                                    className="card-title"
                                    style={{ fontSize: "32px" }}
                                  >
                                    {item.id > -1 ? `[id ${item.id}]: ` : ""}
                                  </div>
                                }
                                {(item.type_id != 10 &&
                                  item.type_id != 11 &&
                                  item.type_id != 12) ||
                                item.id == -1 ? (
                                  item.type_id == 13 &&
                                  IsJsonString(item.content) ? (
                                    <div>
                                      <ul className="card-img-top">
                                        {Object.keys(
                                          JSON.parse(item.content)
                                        ).map((keyFL: any) => (
                                          <li>{`${keyFL}: ${
                                            JSON.parse(item.content)[keyFL]
                                          }`}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  ) : (
                                    <div className="card-img-top">
                                      {item.content}
                                    </div>
                                  )
                                ) : item.type_id == 10 || item.type_id == 11 ? (
                                  <div className="card-img-top">
                                    <a
                                      href={item.content}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.content}
                                    </a>
                                    <br />
                                    <div
                                      className={
                                        windowCheck
                                          ? ""
                                          : "embed-responsive embed-responsive-16by9"
                                      }
                                    >
                                      <iframe
                                        width="400"
                                        height="225"
                                        allowFullScreen
                                        src={`${item.content}`}
                                      ></iframe>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card-img-top">
                                    <a
                                      href={item.content}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.content}
                                    </a>
                                    <br />
                                    <img
                                      className={windowCheck ? "" : "card-img"}
                                      style={{
                                        maxWidth: "840px",
                                      }}
                                      src={item.content}
                                      alt={`pic from id:${item.id}`}
                                    ></img>
                                    <br />
                                  </div>
                                )}

                                {item.hasOwnProperty("answer") &&
                                item.answer !== "" ? (
                                  <div className="card-text">
                                    <button
                                      style={{
                                        display: "inline",
                                        minWidth: "140px",
                                        minHeight: "40px",
                                      }}
                                      onClick={() => {
                                        let x = document.getElementById(
                                          `dispEntriesFlagAns_${item.id}`
                                        );
                                        if (x) {
                                          if (x.style.display === "none") {
                                            x.style.display = "block";
                                          } else {
                                            x.style.display = "none";
                                          }
                                        }
                                      }}
                                    >
                                      {item.type_id != 10 &&
                                      item.type_id != 11 &&
                                      item.type_id != 12 ? (
                                        <div>Answer : </div>
                                      ) : (
                                        <div>Description:</div>
                                      )}
                                      <div
                                        id={`dispEntriesFlagAns_${item.id}`}
                                        style={{ display: "none" }}
                                      >
                                        {item.answer}
                                      </div>
                                    </button>
                                  </div>
                                ) : (
                                  <br />
                                )}

                                <div className="card-footer">
                                  {item.hasOwnProperty("source") &&
                                  item.source !== "" &&
                                  item.source !== "unknown" ? (
                                    <div>Source: {item.source}</div>
                                  ) : (
                                    ""
                                  )}

                                  {item.hasOwnProperty("user_entered") &&
                                  item.user_entered !== "" ? (
                                    <div>
                                      Contributed by: {item.user_entered}
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {item.hasOwnProperty("date_created") ? (
                                    <div>
                                      Added:{" "}
                                      {item.date_created
                                        .substring(0, 19)
                                        .replace("T", ", ")}{" "}
                                      UTC
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {/* {item.id > -1 ? (
                                  <div
                                    id={`dispEntriesFlag_${item.id}`}
                                    style={{ display: "block" }}
                                  >
                                    Change warn values on this talking point
                                    (pick entries, refresh to see changes):
                                    <br />
                                    <button
                                      className="btn btn-secondary mt-1 ml-3"
                                      onClick={() => {
                                        let tmpVal = 2000;
                                        setFlag(item.id, -9999, tmpVal);
                                        setStatusMsg(
                                          "" +
                                            item.id +
                                            " changed mature: " +
                                            tmpVal
                                        );
                                      }}
                                    >
                                      Mature Awaiting Auto Confirm (=2000)
                                    </button>
                                    <button
                                      className="btn btn-dark mt-1 ml-3"
                                      onClick={() => {
                                        let tmpVal = 5000;
                                        setFlag(item.id, -9999, tmpVal);
                                        setStatusMsg(
                                          "" +
                                            item.id +
                                            " changed mature: " +
                                            tmpVal
                                        );
                                      }}
                                    >
                                      Mature Confirmed (=5000)
                                    </button>
                                    <div>
                                      <button
                                        className="btn btn-warning mt-1 ml-4"
                                        onClick={() => {
                                          let tmpVal = 0;
                                          setFlag(item.id, -9999, tmpVal);
                                          setStatusMsg(
                                            "" +
                                              item.id +
                                              " changed mature: " +
                                              tmpVal
                                          );
                                        }}
                                      >
                                        Unmark as Mature (=0)
                                      </button>
                                      <button
                                        className="btn btn-danger mt-1 ml-4"
                                        onClick={() => {
                                          let tmpVal = -5000;
                                          setFlag(item.id, -9999, tmpVal);
                                          setStatusMsg(
                                            "" +
                                              item.id +
                                              " changed mature: " +
                                              tmpVal
                                          );
                                        }}
                                      >
                                        Confirm as Not Mature (=-5000)
                                      </button>
                                    </div>
                                    <hr
                                      style={{
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                      }}
                                    />
                                    <button
                                      className="btn btn-secondary mt-1 ml-3"
                                      onClick={() => {
                                        let tmpVal = 2000;
                                        setFlag(item.id, tmpVal, -9999);
                                        setStatusMsg(
                                          "" +
                                            item.id +
                                            " changed flagged: " +
                                            tmpVal
                                        );
                                      }}
                                    >
                                      Flag Awaiting Auto Confirm (=2000)
                                    </button>
                                    <button
                                      className="btn btn-dark mt-1 ml-3"
                                      onClick={() => {
                                        let tmpVal = 5000;
                                        setFlag(item.id, tmpVal, -9999);
                                        setStatusMsg(
                                          "" +
                                            item.id +
                                            " changed flagged: " +
                                            tmpVal
                                        );
                                      }}
                                    >
                                      Flag Confirmed (=5000)
                                    </button>
                                    <div>
                                      <button
                                        className="btn btn-warning mt-1 ml-4"
                                        onClick={() => {
                                          let tmpVal = 0;
                                          setFlag(item.id, tmpVal, -9999);
                                          setStatusMsg(
                                            "" +
                                              item.id +
                                              " changed flagged: " +
                                              tmpVal
                                          );
                                        }}
                                      >
                                        Unmark as Flagged (=0)
                                      </button>
                                      <button
                                        className="btn btn-danger mt-1 ml-4"
                                        onClick={() => {
                                          let tmpVal = -5000;
                                          setFlag(item.id, tmpVal, -9999);
                                          setStatusMsg(
                                            "" +
                                              item.id +
                                              " changed flagged: " +
                                              tmpVal
                                          );
                                        }}
                                      >
                                        Confirm as Not Flagged (=-5000)
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )} */}

                                <hr />
                              </div>
                            </div>
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Talk;
